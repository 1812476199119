/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.4/dist/jquery.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/@popperjs/core@2.11.8/dist/umd/popper.min.js
 * - /npm/bootstrap@5.3.3/dist/js/bootstrap.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
